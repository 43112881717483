import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { base64ToArrayBuffer } from '../../components/common/DocumentUpload';
import { setProgress } from './uploadProgressSlice';
import { openMessage } from './showMessageSlice';
import { openSessionExpiredDialog } from './applicationSlice';
import { method } from 'lodash';

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (window.location.pathname !== '/login') {
      api.dispatch(openSessionExpiredDialog(true));
      localStorage.clear();
      // window.location.reload('/login');
    }
  } else if (result.error && (result.error.status === 500 || result.error.status === 400)) {
    // api.dispatch(openMessage({
    //     message: "Something went wrong!",
    //     messageSeverity: 'error'
    // }))
  } else if (result.error && result.error.status == 'FETCH_ERROR') {
    // api.dispatch(openMessage({
    //     message: "Service not available!",
    //     messageSeverity: 'error'
    // }))
  }
  return result;
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_ENDPOINT_URL,
  // baseUrl: "https://api.dev.bloqcube.com/",
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getState().auth.token?.access_token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
      headers.set('Access-Control-Allow-Origin', '*');
    }
    return headers;
  },
});

export const bloqcibeApi = createApi({
  reducerPath: 'bloqcibeApi',
  tagTypes: [
    'Trial',
    'TrialDocuments',
    'SiteList',
    'SiteBasicDetail',
    'TrialSiteDetail',
    'SubjectList',
    'SubjectDashboardDetails',
    'FormConfig',
    'EnrollSubjectFormConfig',
    'DepartmentMeta',
    'SubjectWithdrawList',
    'TrialBookingSlots',
    'userDetails',
    'UserBookedSlots',
    'DOATasks',
    'Sponsors',
    'ConfigurationList',
    'SponserConfigurationList',
    'allMessages',
    'UserContacts',
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'system-admin/auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    forgetPassword: builder.mutation({
      query: ({ email }) => ({
        url: `/trial-mgmt/password/forget-password/${email}`,
        method: 'PUT',
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ payload }) => ({
        url: `/trial-mgmt/sponsor/reset-password`,
        method: 'PUT',
        body: payload,
      }),
    }),

    setProfile: builder.mutation({
      query: ({ sponsorId, userId, payload }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/user/${userId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['userDetails'],
    }),
    verifyOTP: builder.mutation({
      query: ({ payload }) => ({
        url: `/trial-mgmt/sponsor/verifyOTP`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['userDetails'],
    }),
    resendOTP: builder.mutation({
      query: ({ payload }) => ({
        url: `/trial-mgmt/sponsor/resendOTP`,
        method: 'POST',
        body: payload,
      }),
    }),

    createTrial: builder.mutation({
      query: ({ trialPayload, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial`,
        method: 'POST',
        body: trialPayload,
      }),
      invalidatesTags: ['Trial'],
    }),
    updateTrialBasicDetails: builder.mutation({
      query: ({ trialPayload, trialId, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${trialId}`,
        method: 'PATCH',
        body: trialPayload,
      }),
      invalidatesTags: ['Trial'],
    }),
    getTrialDocuments: builder.query({
      query: ({ trialId, sponsorId, data }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/document/list`,
        method: 'POST',
        body: data,
      }),
      providesTags: ['TrialDocuments'],
    }),
    getSiteDocuments: builder.query({
      query: ({ trialId, sponsorId, siteId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/document/list`,
        method: 'POST',
        body: { siteId: siteId },
      }),
    }),
    uploadDoc: builder.mutation({
      queryFn: async ({ url, formData, trialId, sponsorId }, api) => {
        try {
          const token = api.getState().auth.token?.access_token;
          const result = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT_URL}trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/document`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
              },
              onUploadProgress: (upload) => {
                //Set the progress value to show the progress bar
                let uploadloadProgress = Math.round((100 * upload.loaded) / upload.total);
                //console.log("uploadloadProgress", uploadloadProgress);
                api.dispatch(setProgress(uploadloadProgress));
                //api.dispatch(setUploadProgress(uploadloadProgress));
              },
            }
          );
          api.dispatch(setProgress(100));
          return { data: result.data };
        } catch (axiosError) {
          let err = axiosError;
          return {
            error: {
              status: err.response?.status,
              data: err.response?.data || err.message,
            },
          };
        }
      },
      invalidatesTags: ['TrialDocuments'],
    }),
    createTrialPatch: builder.mutation({
      query: ({ id, trialPayload, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${id}/basic-detail`,
        method: 'PATCH',
        body: trialPayload,
      }),
      invalidatesTags: ['Trial'],
    }),
    addSiteMember: builder.mutation({
      query: ({ sponsorId, data }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/user`,
        method: 'POST',
        body: data,
      }),
    }),
    assignRole: builder.mutation({
      query: ({ sponsorId, data }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/assign-role`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['TrialSiteDetail', 'UserContacts'],
    }),
    createTrialSitePatch: builder.mutation({
      query: ({ id, siteDetailsPayload, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${id}/site`,
        method: 'POST',
        body: siteDetailsPayload,
      }),
      invalidatesTags: ['SiteList'],
    }),
    getTrials: builder.mutation({
      query: ({ filter, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/user-trials`,
        method: 'POST',
        body: {
          filter: filter,
        },
      }),
    }),
    getTrialDetails: builder.query({
      query: ({ id, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${id}`,
      }),
      providesTags: (result, error, arg) => {
        return result ? [{ type: 'Trial', id: result.id }, 'Trial'] : ['Trial'];
      },
    }),
    getOngoingTrials: builder.query({
      query: (sponsorId) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trials/on-going`,
      }),
      providesTags: (result, error, arg) => {
        return result ? [{ type: 'Trial', id: result.id }, 'Trial'] : ['Trial'];
      },
    }),
    getTrialSiteDetails: builder.query({
      query: ({ trialId, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/sites`,
      }),
      providesTags: ['SiteList'],
    }),
    getTrialVisitDetails: builder.query({
      query: ({ trialId }) => ({
        url: `/e-consent/crf/step-master/trial/${trialId}`,
      }),
    }),
    getSiteBasicDetails: builder.query({
      query: ({ sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/sites`,
      }),
      providesTags: ['SiteBasicDetail'],
    }),
    getSiteMembers: builder.query({
      query: ({ sponsorId, siteId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/site/${siteId}/users`,
      }),
    }),
    getTrialSiteMembers: builder.query({
      query: ({ sponsorId, trialId, siteId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/assign-users`,
      }),
    }),
    deleteTrialDocuments: builder.mutation({
      query: ({ trialId, s3Key, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/delete-document`,
        method: 'DELETE',
        body: { key: s3Key },
      }),
      invalidatesTags: ['TrialDocuments'],
    }),
    deleteUserRole: builder.mutation({
      query: ({ user_role_id }) => ({
        url: `/trial-mgmt/sponsor/user/${user_role_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TrialSiteDetail', 'UserContacts'],
    }),
    getDownloadTrialDoc: builder.query({
      queryFn: async ({ trialId, fileName, s3Key, sponsorId }, api) => {
        try {
          const token = api.getState().auth.token?.access_token;
          const response = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT_URL}trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/download-document`,
            { key: s3Key },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          var byteArray = base64ToArrayBuffer(response.data);
          var a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
          a.download = decodeURIComponent(fileName);
          document.body.appendChild(a);
          a.click();
        } catch (axiosError) {
          let err = axiosError;
          return {
            error: {
              status: err.response?.status,
              data: err.response?.data || err.message,
            },
          };
        }
      },
    }),
    getTrialDocTypes: builder.query({
      query: () => ({
        url: `/trial-mgmt/doctypes`,
      }),
    }),
    updateTrialBankDetail: builder.mutation({
      query: ({ sponsorId, trialId, bankDetails }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/bank-detail/${bankDetails.id}`,
        method: 'PATCH',
        body: bankDetails,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Trial', id: arg.trialId }],
    }),
    updateTrialStudyDetail: builder.mutation({
      query: ({ sponsorId, trialId, studyDetail }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/study/${studyDetail.studyDetail.id}`,
        method: 'PATCH',
        body: studyDetail,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Trial', id: arg.trialId }],
    }),
    updateTrialPatch: builder.mutation({
      query: ({ id, trialPayload, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${id}`,
        method: 'PATCH',
        body: trialPayload,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Trial', id: arg.trialId }],
    }),
    ///trial-mgmt/sponsor/{sponsorId}/trial/{trialId}/trial-budget
    updateTrialBudgetDetail: builder.mutation({
      query: ({ sponsorId, trialId, budgetDetail }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/trial-budget`,
        method: 'PATCH',
        body: budgetDetail,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Trial', id: arg.trialId }],
    }),
    getSiteDocumentMeta: builder.query({
      query: () => ({
        url: '/trial-mgmt/site/doctypes',
      }),
    }),
    markTrialSetupDone: builder.mutation({
      query: ({ sponsorId, trialId, status }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/${status}`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Trial', id: arg.trialId }, 'Trial'],
    }),
    getDepartmentMeta: builder.query({
      query: () => ({
        url: '/trial-mgmt/department',
      }),
      providesTags: ['DepartmentMeta'],
    }),
    getDynamicDataValues: builder.query({
      query: ({ url }) => ({
        url: url,
      }),
    }),
    createSite: builder.mutation({
      query: ({ sponsorId, siteData }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/site`,
        body: siteData,
        method: 'POST',
      }),
      invalidatesTags: ['SiteBasicDetail'],
    }),
    getTrialSiteInfo: builder.query({
      query: ({ sponsorId, trialId, siteTrialId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trial/${trialId}/site-trial/${siteTrialId}`,
      }),
      providesTags: ['TrialSiteDetail'],
    }),
    getLibraryForm: builder.query({
      query: (path) => ({
        // url: `form-library/library/${path}`,
        url: path,
      }),
      providesTags: ['FormConfig'],
    }),

    getJsonForm: builder.query({
      queryFn: async ({ sponsorId, documentKey }, api) => {
        try {
          const token = api.getState().auth.token?.access_token;
          const response = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT_URL}form-library/library/file/${sponsorId}/${documentKey}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (response?.data) {
            const decodedData = atob(response.data);
            const jsonData = JSON.parse(decodedData);

            const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json' });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = `${documentKey}.json`;

            document.body.appendChild(a);
            a.click();
            a.remove();
            return '';
          } else {
            return '';
          }
        } catch (axiosError) {
          return {
            error: {
              status: axiosError?.response?.status,
              data: axiosError?.response?.data || axiosError?.message,
            },
          };
        }
      },
    }),
    uploadSignature: builder.mutation({
      query: ({ sponsorId, trialId, trialSiteId, payload }) => ({
        url: `/trial-mgmt/questionnaire/sponsor/${sponsorId}/trial/${trialId}/trial-site/${trialSiteId}/signture-upload`,
        body: payload,
        method: 'POST',
      }),
    }),
    uploadVisitSignature: builder.mutation({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/e-consent/crf/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/signture-upload`,
        body: payload,
        method: 'POST',
      }),
    }),
    uploadCRFFile: builder.mutation({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/e-consent/crf/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/document-upload`,
        body: payload,
        method: 'POST',
      }),
    }),
    uploadInitiationFile: builder.mutation({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/trial-mgmt/questionnaire/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/document-upload`,
        body: payload,
        method: 'POST',
      }),
    }),
    uploadSubjectProfilePic: builder.mutation({
      query: ({ subjectMasterId, payload }) => ({
        url: `/e-consent/subject/${subjectMasterId}/profile-pic`,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: ['SubjectDashboardDetails'],
    }),
    uploadSubjectFile: builder.mutation({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/e-consent/subject/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/document-upload`,
        body: payload,
        method: 'POST',
      }),
    }),
    downloadSignature: builder.query({
      query: ({ s3Key }) => ({
        url: `/trial-mgmt/questionnaire/signture`,
        method: 'GET',
        params: {
          key: s3Key,
        },
      }),
    }),
    downloadSubjectProfilePic: builder.query({
      query: ({ s3Key }) => ({
        url: `/e-consent/subject/subject/profile-pic`,
        method: 'GET',
        params: {
          key: s3Key,
        },
      }),
    }),
    downloadVisitSignature: builder.query({
      query: ({ s3Key }) => ({
        url: `/e-consent/crf/signture`,
        method: 'GET',
        params: {
          key: s3Key,
        },
      }),
    }),
    downloadCRFFile: builder.query({
      query: ({ s3Key }) => ({
        url: `/e-consent/crf/document`,
        method: 'GET',
        params: {
          key: s3Key,
        },
      }),
    }),
    downloadInitiationFile: builder.query({
      query: ({ s3Key }) => ({
        url: `/trial-mgmt/questionnaire/document`,
        method: 'GET',
        params: {
          key: s3Key,
        },
      }),
    }),
    downloadSubjectFile: builder.query({
      query: ({ s3Key }) => ({
        url: `/e-consent/subject/document`,
        method: 'GET',
        params: {
          key: s3Key,
        },
      }),
    }),
    downloadSubjectSignature: builder.query({
      query: ({ s3Key }) => ({
        url: `/e-consent/subject/signture`,
        method: 'GET',
        params: {
          key: s3Key,
        },
      }),
    }),
    submitSiteInitAnswers: builder.mutation({
      query: ({ sponsorId, trialId, trialSiteId, payload }) => ({
        url: `/trial-mgmt/questionnaire/sponsor/${sponsorId}/trial/${trialId}/trial-site/${trialSiteId}`,
        body: payload,
        method: 'POST',
      }),
    }),
    updateSiteInitAnswers: builder.mutation({
      query: ({ sponsorId, trialId, trialSiteId, payload }) => ({
        url: `/trial-mgmt/questionnaire/sponsor/${sponsorId}/trial/${trialId}/trial-site/${trialSiteId}`,
        body: payload,
        method: 'PUT',
      }),
    }),
    verifySiteInitAnswers: builder.mutation({
      query: ({ sponsorId, trialId, trialSiteId, payload }) => ({
        url: `/trial-mgmt/questionnaire/sponsor/${sponsorId}/trial/${trialId}/trial-site/${trialSiteId}`,
        body: payload,
        method: 'PATCH',
      }),
    }),
    getTrialSiteAnswers: builder.query({
      query: ({ sponsorId, trialId, trialSiteId, payload }) => ({
        url: `/trial-mgmt/questionnaire/sponsor/${sponsorId}/trial/${trialId}/trial-site/${trialSiteId}/site-init-answers`,
        body: payload,
        method: 'POST',
      }),
    }),
    getVisitAnswers: builder.query({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/e-consent/crf/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/subject-answers`,
        body: payload,
        method: 'POST',
      }),
    }),
    getSponsorDetails: builder.query({
      query: (sponsorId) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}`,
      }),
    }),
    getSponsorLogo: builder.query({
      query: ({ key, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/logo/${key}`,
      }),
    }),
    saveSubjectEnrollInfo: builder.mutation({
      query: ({ trialId, siteId, sponsorId, payload }) => ({
        method: 'POST',
        url: `/e-consent/subject/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}`,
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => {
        if (result?.id) {
          return [{ type: 'SubjectList', id: result.id }, 'SubjectList'];
        } else {
          return ['SubjectList'];
        }
      },
    }),
    saveVisitAnswers: builder.mutation({
      query: ({ trialId, siteId, sponsorId, payload }) => ({
        method: 'POST',
        url: `/e-consent/crf/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}`,
        body: payload,
      }),
      // invalidatesTags: (result, error, arg) => error ? [] : [{ type: 'SubjectList', id: result.id }, 'SubjectList'],
    }),
    saveWithdrawSubAnswers: builder.mutation({
      query: ({ trialId, siteId, sponsorId, payload }) => ({
        method: 'POST',
        url: `/e-consent/subject/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/withdrawl`,
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'SubjectList', id: result.id },
        'SubjectList',
        'SubjectWithdrawList',
      ],
    }),
    saveComment: builder.mutation({
      query: ({ payload }) => ({
        method: 'POST',
        url: `/e-consent/crf/comment`,
        body: payload,
      }),
    }),
    getSubjectList: builder.query({
      query: ({ sponsorId, trialId, siteId }) => ({
        url: `/e-consent/subject/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/subjects`,
      }),
      providesTags: ['SubjectList'],
    }),
    getWithdrawSubjectList: builder.query({
      query: ({ sponsorId, trialId, siteId }) => ({
        url: `/e-consent/subject/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/withdraw-subjects`,
      }),
      providesTags: ['SubjectWithdrawList'],
    }),
    getSubjectAnswers: builder.query({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/e-consent/subject/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/subject-answers`,
        method: 'POST',
        body: payload,
      }),
    }),
    getComments: builder.query({
      query: ({ sponsorId, trialId, trialSiteId, subjectMasterId, payload }) => ({
        url: `/e-consent/crf/comment/sponsor/${sponsorId}/trial/${trialId}/trial-site/${trialSiteId}/subject/${subjectMasterId}`,
        method: 'POST',
        body: payload,
      }),
    }),
    getFieldHistory: builder.query({
      query: ({ payload }) => ({
        url: `/e-consent/crf/fieldHistory`,
        method: 'POST',
        body: payload,
      }),
    }),
    getSubjectFieldHistory: builder.query({
      query: ({ payload }) => ({
        url: `/e-consent/subject/fieldHistory`,
        method: 'POST',
        body: payload,
      }),
    }),
    uploadSubjectSignature: builder.mutation({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/e-consent/subject/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/signture-upload`,
        body: payload,
        method: 'POST',
      }),
    }),
    getSubjectDetail: builder.query({
      query: (subjectMasterId) => ({
        url: `/e-consent/subject/${subjectMasterId}`,
      }),
      providesTags: (result, error, arg) => {
        return result ? [{ type: 'SubjectList', id: result.id }, 'SubjectList'] : ['SubjectList'];
      },
    }),
    getSubjectDashboardDetail: builder.query({
      query: (subjectMasterId) => ({
        url: `/e-consent/subject/subject/${subjectMasterId}/detail`,
      }),
      providesTags: ['SubjectDashboardDetails'],
    }),
    addFieldSubjectEnrollment: builder.mutation({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/e-consent/subject/${sponsorId}/trial/${trialId}/site/${siteId}/custom-field`,
        body: payload,
        method: 'POST',
      }),
      //invalidatesTags:["FormConfig"]
    }),
    addFieldSiteInitiation: builder.mutation({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/trial-mgmt/questionnaire/sponsor/${sponsorId}/trial/${trialId}/custom-field`,
        body: payload,
        method: 'POST',
      }),
      //invalidatesTags:["FormConfig"]
    }),
    removeFieldSubjectEnrollment: builder.mutation({
      query: (fieldId) => ({
        url: `/e-consent/subject/custom-field/${fieldId}`,
        method: 'DELETE',
      }),
    }),
    removeFieldSiteInitiation: builder.mutation({
      query: (fieldId) => ({
        url: `/trial-mgmt/questionnaire/custom-field/${fieldId}`,
        method: 'DELETE',
      }),
    }),
    generateOTPValue: builder.mutation({
      query: () => ({
        url: `/trial-mgmt/sponsor/generateOTP`,
        body: {},
        method: 'POST',
      }),
    }),
    playConsentAudio: builder.mutation({
      queryFn: async ({ payload }, api) => {
        try {
          const token = api.getState().auth.token?.access_token;
          const response = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT_URL}e-consent/subject/consent/synthesizeSpeech`,
            payload,
            { headers: { Authorization: `Bearer ${token}` }, responseType: 'stream' }
          );
          return { data: response.data };
        } catch (axiosError) {
          let err = axiosError;
          return {
            error: {
              status: err.response?.status,
              data: err.response?.data || err.message,
            },
          };
        }
      },
    }),
    resendOTPValue: builder.mutation({
      query: () => ({
        url: `/trial-mgmt/sponsor/resendOTP`,
        body: {},
        method: 'POST',
      }),
    }),
    verifyOTPValue: builder.mutation({
      query: ({ payload }) => ({
        url: `/trial-mgmt/sponsor/verifyOTP`,
        body: payload,
        method: 'POST',
      }),
    }),
    getEConsentFormConfig: builder.query({
      query: ({ sponsorId, trialId, siteId, documentKey, payload }) => ({
        url: `/e-consent/subject/${sponsorId}/trial/${trialId}/site/${siteId}/subject-enroll/${documentKey}`,
        body: payload,
        method: 'POST',
      }),
      providesTags: ['EnrollSubjectFormConfig'],
    }),
    getSiteInitiationConfig: builder.query({
      query: ({ sponsorId, trialId, documentKey, siteId }) => ({
        url: `/trial-mgmt/questionnaire/sponsor/${sponsorId}/trial/${trialId}/site-initiation/${documentKey}`,
        body: { siteId: siteId },
        method: 'POST',
      }),
      providesTags: ['EnrollSubjectFormConfig'],
    }),
    externalVerifySubject: builder.mutation({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/e-consent/subject/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/verify`,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'SubjectList', id: result.id }, 'SubjectList'],
    }),
    externalVerifyCRF: builder.mutation({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/e-consent/crf/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/verify`,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'SubjectList', id: result.id }, 'SubjectList'],
    }),
    AddDepartmentInMeta: builder.mutation({
      query: (dept) => ({
        url: '/trial-mgmt/department',
        method: 'POST',
        body: { title: dept },
      }),
      invalidatesTags: ['DepartmentMeta'],
    }),
    getSubjectListByField: builder.mutation({
      query: ({ sponsorId, trialId, siteId, payload }) => ({
        url: `/e-consent/crf/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/subjects`,
        body: payload,
        method: 'POST',
      }),
    }),
    createSchedule: builder.mutation({
      query: ({ trialId, siteId, payload }) => ({
        url: `/trial-mgmt/schedule/trial/${trialId}/site/${siteId}`,
        body: payload,
        method: 'POST',
      }),
    }),
    getSchedule: builder.mutation({
      query: ({ trialId, payload }) => ({
        url: `/trial-mgmt/schedule/trial/${trialId}/events`,
        body: payload,
        method: 'POST',
      }),
    }),
    addCommentOnScheduleEvent: builder.mutation({
      query: ({ scheduleId, payload }) => ({
        url: `/trial-mgmt/schedule/schedule/${scheduleId}/comment`,
        body: payload,
        method: 'POST',
      }),
    }),
    getOneSchedule: builder.query({
      query: (scheduleId) => ({
        url: `/trial-mgmt/schedule/${scheduleId}`,
        method: 'GET',
      }),
    }),
    getMeUser: builder.query({
      query: () => ({
        url: `/trial-mgmt/sponsor/users/me`,
        method: 'GET',
      }),
      providesTags: ['userDetails'],
    }),
    uploadUserProfilePic: builder.mutation({
      query: ({ payload }) => ({
        url: `/trial-mgmt/sponsor/user/profile-pic`,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: ['userDetails'],
    }),
    downloadUserProfilePic: builder.query({
      query: ({ s3Key }) => ({
        url: `/trial-mgmt/sponsor/user/profile-pic`,
        method: 'GET',
        params: {
          key: s3Key,
        },
      }),
    }),
    updateSchedule: builder.mutation({
      query: ({ scheduleId, trialId, siteId, payload }) => ({
        url: `/trial-mgmt/schedule/trial/${trialId}/site/${siteId}/schedule/${scheduleId}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    createTrialBookingSlot: builder.mutation({
      query: ({ visitId, trialId, payload }) => ({
        url: `/trial-mgmt/schedule/booking/trial/${trialId}/visit/${visitId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['TrialBookingSlots'],
    }),
    getTrialBookingSlots: builder.query({
      query: () => ({
        url: `/trial-mgmt/schedule/booking/slots`,
      }),
      providesTags: ['TrialBookingSlots'],
    }),
    deleteTrialBooking: builder.mutation({
      query: ({ visitId, trialId, siteId, slotId }) => ({
        url: `/trial-mgmt/schedule/booking/slotId/${slotId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TrialBookingSlots'],
    }),
    updateTrialBookingSlot: builder.mutation({
      query: ({ slotId, payload }) => ({
        url: `/trial-mgmt/schedule/booking/slotId/${slotId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['TrialBookingSlots'],
    }),
    publishBookingSlot: builder.mutation({
      query: ({ visitId, trialId, payload }) => ({
        url: `/trial-mgmt/schedule/booking/trial/${trialId}/visit/${visitId}/publish`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['TrialBookingSlots'],
    }),
    dashboradData: builder.query({
      query: ({ data }) => ({
        url: `/trial-mgmt/v1/dashboard`,
        method: 'POST',
        body: data,
      }),
    }),
    updateDashboardPreference: builder.mutation({
      query: (payload) => ({
        url: `/trial-mgmt/v1/dashboard/preferences`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    bookVisitSlot: builder.mutation({
      query: ({ slotId, userId }) => ({
        url: `/trial-mgmt/schedule/booking/slot/${slotId}/user/${userId}`,
        method: 'POST',
      }),
      invalidatesTags: ['UserBookedSlots'],
    }),
    cancelVisitSlot: builder.mutation({
      query: ({ slotId, userId }) => ({
        url: `/trial-mgmt/schedule/booking/slot/${slotId}/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserBookedSlots'],
    }),
    cancelScheduledEvent: builder.mutation({
      query: ({ scheduleId }) => ({
        url: `/trial-mgmt/schedule/${scheduleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserBookedSlots'],
    }),
    //DOA
    fetchDAO: builder.query({
      query: ({ sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trials/doa`,
      }),
    }),
    fetchAllAssigngedTasks: builder.query({
      query: ({ trialId, siteId }) => ({
        url: `/trial-mgmt/doa/assigned-task/trial/${trialId}/site/${siteId}`,
      }),
      providesTags: ['DOAAllAssignedTasks'],
    }),
    fetchUserAssigngedTasks: builder.query({
      query: ({ trialId, siteId, userId }) => ({
        url: `/trial-mgmt/doa/assigned-task/trial/${trialId}/site/${siteId}?userId=${userId}`,
      }),
      providesTags: ['DOAAllAssignedTasks'],
    }),
    fetchAllDAOTasks: builder.query({
      query: ({ trialId, siteId }) => ({
        url: `/trial-mgmt/doa/trial/${trialId}/site/${siteId}`,
      }),
      providesTags: ['DOATasks'],
    }),
    assignTask: builder.mutation({
      query: ({ userId, payload }) => ({
        url: `/trial-mgmt/doa/assign-tasks/user/${userId}`,
        method: 'POST',
        body: payload,
      }),
    }),
    deleteAssignedTask: builder.mutation({
      query: ({ assignedTaskId }) => ({
        url: `/trial-mgmt/doa/assigned-task/${assignedTaskId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DOAAllAssignedTasks'],
    }),
    saveDOASign: builder.mutation({
      query: ({ assignedTaskId, payload }) => ({
        url: `/trial-mgmt/doa/assigned-task/${assignedTaskId}/document-upload`,
        method: 'POST',
        body: payload,
      }),
    }),
    changeDOATaskStatus: builder.mutation({
      query: ({ assignedTaskId, status }) => ({
        url: `/trial-mgmt/doa/assigned-task/${assignedTaskId}/status/${status}`,
        method: 'PATCH',
      }),
    }),
    bulkVerifyDOATasks: builder.mutation({
      query: (payload) => ({
        url: '/trial-mgmt/doa/bulk-task-verification',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['DOAAllAssignedTasks'],
    }),
    addDOAComment: builder.mutation({
      query: ({ assignedTaskId, payload }) => ({
        url: `/trial-mgmt/doa/assigned-task/${assignedTaskId}/comment`,
        method: 'POST',
        body: payload,
      }),
    }),
    downloadDOASignature: builder.query({
      query: ({ s3Key }) => ({
        url: `/trial-mgmt/doa/assigned-task/download-document`,
        method: 'GET',
        params: {
          key: s3Key,
        },
      }),
    }),
    deleteDOADocuments: builder.mutation({
      query: (s3Key) => ({
        url: `/trial-mgmt/doa/assigned-task/delete-document`,
        method: 'DELETE',
        params: {
          key: s3Key,
        },
      }),
    }),
    updateTask: builder.mutation({
      query: ({ payload, trialId, siteId, taskId }) => ({
        url: `/trial-mgmt/doa/trial/${trialId}/site/${siteId}/task/${taskId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['DOATasks'],
    }),
    updateAssignedTask: builder.mutation({
      query: ({ assignedTaskId, payload }) => ({
        url: `/trial-mgmt/doa/assigned-task/${assignedTaskId}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    createDoaTask: builder.mutation({
      query: ({ payload }) => ({
        url: `/trial-mgmt/doa/task`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['DOATasks'],
    }),
    deleteDoaTask: builder.mutation({
      query: (taskId) => ({
        url: `/trial-mgmt/doa/task/${taskId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DOATasks'],
    }),
    uploadDoaTaskDocument: builder.mutation({
      query: ({ assignedTaskId, payload }) => ({
        url: `/trial-mgmt/doa/assigned-task/${assignedTaskId}/document-upload`,
        body: payload,
        method: 'POST',
      }),
    }),
    getUserBookedSlots: builder.query({
      query: (userId) => ({
        url: `/trial-mgmt/schedule/booking/user/${userId}`,
      }),
      providesTags: ['UserBookedSlots'],
    }),
    getBookedTrialsSlots: builder.query({
      query: (payload) => ({
        url: `/trial-mgmt/schedule/booking/booked-slots`,
        method: 'POST',
        body: payload,
      }),
      providesTags: ['UserBookedSlots'],
    }),
    downloadAuditFile: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/audit-log/export-csv${query}`,
        method: 'POST',
        body: payload,
        responseHandler: async (response) => {
          if (response.headers.get('Content-Type')?.includes('text/csv')) {
            // Handle CSV response
            return response.text();
          } else if (response.headers.get('Content-Type')?.includes('application/pdf')) {
            return response.blob();
          } else {
            // Handle JSON response
            return response.json();
          }
        },
      }),
    }),
    downloadAESAEReport: builder.query({
      query: ({ sponsorId, trialId, siteId, documentKey, type }) => ({
        url: `/e-consent/crf/report/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}/documentKey/${documentKey}/type/${type}`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response.headers.get('Content-Type')?.includes('text/csv')) {
            // Handle CSV response
            return response.text();
          } else if (response.headers.get('Content-Type')?.includes('application/pdf')) {
            // Handle PDF response
            const data = await response.blob();
            const blobUrl = URL.createObjectURL(data);
            return blobUrl;
          } else {
            // Handle JSON response
            return response.json();
          }
        },
      }),
    }),
    getSponsors: builder.query({
      query: () => ({
        url: 'trial-mgmt/sponsor/all',
      }),
      providesTags: ['Sponsors'],
    }),
    addSponsors: builder.mutation({
      query: ({ payload }) => ({
        url: '/trial-mgmt/sponsor',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Sponsors'],
    }),
    addSponsorConfiguration: builder.mutation({
      query: ({ payload, sponsorId }) => ({
        url: `/trial-mgmt/dynamic-configurations/mapping/sponser/${sponsorId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['SponserConfigurationList', 'ConfigurationList'],
    }),
    getSponsorById: builder.query({
      query: (sponsorId) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}`,
      }),
      providesTags: ['Sponsors'],
    }),
    uploadOrganizationLogo: builder.mutation({
      query: ({ payload, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/logo`,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: ['Sponsors'],
    }),
    addSponsorAdmin: builder.mutation({
      query: ({ payload, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/sponsor-admin-user`,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: ['Sponsors'],
    }),
    getSponsorAdminById: builder.query({
      query: (sponsorId) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/sponsor-admin-user`,
      }),
      providesTags: ['Sponsors'],
    }),
    getAllConfigurations: builder.query({
      query: () => ({
        url: '/trial-mgmt/dynamic-configurations/configuration-labels',
      }),
      providesTags: ['ConfigurationList'],
    }),
    getAllSponserConfigurations: builder.query({
      query: (sponsorId) => ({
        url: `/trial-mgmt/dynamic-configurations/mapping/sponser/${sponsorId}`,
      }),
      providesTags: ['SponserConfigurationList'],
    }),
    addConfiguration: builder.mutation({
      query: (newConfiguration) => ({
        url: `/trial-mgmt/dynamic-configurations/configuration`,
        method: 'POST',
        body: newConfiguration,
      }),
      invalidatesTags: ['ConfigurationList'],
    }),
    deleteConfigurationOption: builder.mutation({
      query: (optionId) => ({
        url: `/trial-mgmt/dynamic-configurations/option/${optionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ConfigurationList'],
    }),
    updateConfigurationOption: builder.mutation({
      query: ({ optionId, payload }) => ({
        url: `/trial-mgmt/dynamic-configurations/option/${optionId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['ConfigurationList'],
    }),
    deleteConfigurationTable: builder.mutation({
      query: (labelId) => ({
        url: `/trial-mgmt/dynamic-configurations/${labelId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ConfigurationList'],
    }),
    addTrailSys: builder.mutation({
      query: ({ payload, sponsorId }) => ({
        url: `/trial-mgmt/sponsor/sponsor/${sponsorId}/v2/trial`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Trail'],
    }),
    getTrailSys: builder.query({
      query: (sponsorId) => ({
        url: `/trial-mgmt/sponsor/${sponsorId}/trials`,
      }),
      providesTags: ['Trail'],
    }),
    uploadConfigurationFile: builder.mutation({
      query: (formData) => ({
        url: '/form-library/library',
        method: 'POST',
        body: formData,
        formData: true, // Set to true for multipart/form-data
      }),
    }),
    updateConfigurationTable: builder.mutation({
      query: (updateConfiguration) => ({
        url: `/trial-mgmt/dynamic-configurations/configuration`,
        method: 'PATCH',
        body: updateConfiguration,
      }),
      invalidatesTags: ['ConfigurationList'],
    }),
    downloadDelegationLogs: builder.query({
      query: ({ trialId, siteId }) => ({
        url: `/trial-mgmt/doa/deligation-log/trial/${trialId}/site/${siteId}`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response.headers.get('Content-Type')?.includes('text/csv')) {
            // Handle CSV response
            return response.text();
          } else if (response.headers.get('Content-Type')?.includes('application/pdf')) {
            // Handle PDF response
            const data = await response.blob();
            const blobUrl = URL.createObjectURL(data);
            return blobUrl;
          } else {
            // Handle JSON response
            return response.json();
          }
        },
      }),
    }),
    downloadVisitCsv: builder.mutation({
      query: ({ sponsorId, trialId, siteId }) => ({
        url: `/e-consent/crf/report/sponsor/${sponsorId}/trial/${trialId}/site/${siteId}`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response.headers.get('Content-Type')?.includes('text/csv')) {
            // Handle CSV response
            return response.text();
          } else if (response.headers.get('Content-Type')?.includes('application/pdf')) {
            return response.blob();
          } else {
            // Handle JSON response
            return response.json();
          }
        },
      }),
    }),

    // Telehealth Api's
    sendMessage: builder.mutation({
      query: (payload) => ({
        url: '/trial-mgmt/messages',
        method: 'POST',
        body: payload,
      }),
    }),
    getMessageThreads: builder.query({
      query: () => ({
        url: '/trial-mgmt/messages',
        method: 'GET',
      }),
      providesTags: ['allMessages'],
    }),
    getSingleChatThread: builder.query({
      query: (sentBy) => ({
        url: `/trial-mgmt/messages/${sentBy}`,
      }),
    }),
    readMessage: builder.mutation({
      query: (payload) => ({
        url: 'trial-mgmt/messages/status',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['allMessages'],
    }),
    getMeetingDetails: builder.query({
      query: (meetingId) => ({
        url: `/trial-mgmt/telehealth/meeting/${meetingId}`,
        method: 'GET',
      }),
    }),
    getUserContacts: builder.query({
      query: () => ({
        url: `/trial-mgmt/sponsor/user/contacts`,
        method: 'GET',
      }),
      providesTags: ['UserContacts'],
    }),
    getSQSCreds: builder.query({
      query: (meetingId) => ({
        url: `/trial-mgmt/telehealth/assume-role`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useSetProfileMutation,
  useVerifyOTPMutation,
  useResendOTPMutation,
  useBookVisitSlotMutation,
  useCreateTrialMutation,
  useUpdateTrialBasicDetailsMutation,
  useUploadDocMutation,
  useCreateTrialPatchMutation,
  useGetTrialsMutation,
  useGetSiteMembersQuery,
  useGetTrialSiteDetailsQuery,
  useGetTrialVisitDetailsQuery,
  useGetDownloadTrialDocQuery,
  useGetTrialSiteMembersQuery,
  useUploadSignatureMutation,
  useAssignRoleMutation,
  useAddSiteMemberMutation,
  useDeleteAssignedTaskMutation,
  useDeleteTrialDocumentsMutation,
  useDeleteUserRoleMutation,
  useGetTrialDetailsQuery,
  useUploadSubjectFileMutation,
  useUpdateAssignedTaskMutation,
  useBulkVerifyDOATasksMutation,
  useDownloadSubjectFileQuery,
  useDownloadDOASignatureQuery,
  useGetSubjectDashboardDetailQuery,
  usePlayConsentAudioMutation,
  useAddDOACommentMutation,
  useDownloadSubjectSignatureQuery,
  useGetTrialDocTypesQuery,
  useDownloadVisitSignatureQuery,
  useGetTrialDocumentsQuery,
  useFetchUserAssigngedTasksQuery,
  useGetSiteBasicDetailsQuery,
  useDownloadCRFFileQuery,
  useCreateTrialSitePatchMutation,
  useUpdateTrialBankDetailMutation,
  useUpdateTrialStudyDetailMutation,
  useUpdateTrialPatchMutation,
  useUpdateTrialBudgetDetailMutation,
  useGetDynamicDataValuesQuery,
  useGetSiteDocumentMetaQuery,
  useGetSiteDocumentsQuery,
  useMarkTrialSetupDoneMutation,
  useGetOngoingTrialsQuery,
  useGetDepartmentMetaQuery,
  useCreateSiteMutation,
  useGetVisitAnswersQuery,
  useGetTrialSiteInfoQuery,
  useGetLibraryFormQuery,
  useGetJsonFormQuery,
  useUploadCRFFileMutation,
  useGetCommentsQuery,
  useGetFieldHistoryQuery,
  useChangeDOATaskStatusMutation,
  useGetSubjectFieldHistoryQuery,
  useSaveDOASignMutation,
  useGenerateOTPValueMutation,
  useVerifyOTPValueMutation,
  useSaveCommentMutation,
  useUploadVisitSignatureMutation,
  useSubmitSiteInitAnswersMutation,
  useGetTrialSiteAnswersQuery,
  useUpdateSiteInitAnswersMutation,
  useVerifySiteInitAnswersMutation,
  useSaveVisitAnswersMutation,
  useGetSponsorDetailsQuery,
  useGetSponsorLogoQuery,
  useSaveSubjectEnrollInfoMutation,
  useGetSubjectListQuery,
  useGetSubjectAnswersQuery,
  useUploadSubjectSignatureMutation,
  useDownloadSubjectProfilePicQuery,
  useUploadSubjectProfilePicMutation,
  useGetSubjectDetailQuery,
  useAddFieldSubjectEnrollmentMutation,
  useGetEConsentFormConfigQuery,
  useUploadInitiationFileMutation,
  useDownloadInitiationFileQuery,
  useExternalVerifySubjectMutation,
  useExternalVerifyCRFMutation,
  useResendOTPValueMutation,
  useAddDepartmentInMetaMutation,
  useSaveWithdrawSubAnswersMutation,
  useGetWithdrawSubjectListQuery,
  useGetSubjectListByFieldMutation,
  useCreateScheduleMutation,
  useGetScheduleMutation,
  useAddCommentOnScheduleEventMutation,
  useGetOneScheduleQuery,
  useGetMeUserQuery,
  useUploadUserProfilePicMutation,
  useDownloadUserProfilePicQuery,
  useUpdateScheduleMutation,
  useGetSiteInitiationConfigQuery,
  useAddFieldSiteInitiationMutation,
  useRemoveFieldSubjectEnrollmentMutation,
  useRemoveFieldSiteInitiationMutation,
  useCreateTrialBookingSlotMutation,
  useGetTrialBookingSlotsQuery,
  useDeleteTrialBookingMutation,
  useUpdateTrialBookingSlotMutation,
  usePublishBookingSlotMutation,
  useDashboradDataQuery,
  useUpdateDashboardPreferenceMutation,
  useCancelVisitSlotMutation,
  useGetBookedTrialsSlotsQuery,
  useFetchDAOQuery,
  useFetchAllDAOTasksQuery,
  useDeleteDOADocumentsMutation,
  useCreateDoaTaskMutation,
  useDeleteDoaTaskMutation,
  useUpdateTaskMutation,
  useAssignTaskMutation,
  useCancelScheduledEventMutation,
  useUploadDoaTaskDocumentMutation,
  useDownloadAuditFileMutation,
  useDownloadAESAEReportQuery,
  useGetSponsorsQuery,
  useAddSponsorsMutation,
  useAddSponsorConfigurationMutation,
  useGetSponsorByIdQuery,
  useUploadOrganizationLogoMutation,
  useAddSponsorAdminMutation,
  useGetSponsorAdminByIdQuery,
  useAddConfigurationMutation,
  useGetAllConfigurationsQuery,
  useGetAllSponserConfigurationsQuery,
  useDeleteConfigurationOptionMutation,
  useUpdateConfigurationOptionMutation,
  useDeleteConfigurationTableMutation,
  useUpdateConfigurationTableMutation,
  useLazyDownloadDelegationLogsQuery,
  useAddTrailSysMutation,
  useGetTrailSysQuery,
  useUploadConfigurationFileMutation,
  useDownloadVisitCsvMutation,
  useSendMessageMutation,
  useGetMessageThreadsQuery,
  useGetSingleChatThreadQuery,
  useReadMessageMutation,
  useGetMeetingDetailsQuery,
  useGetUserContactsQuery,
  useGetSQSCredsQuery
} = bloqcibeApi;
